import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  ECityCode,
  EGuideSearchType,
  ESearchLocationType,
  ESortOption,
  ESuggestSearchType,
} from 'types/Search';

import {StoreState} from 'ducks/store';
import {
  ESearchCollectionType,
  TAddressSearch,
  TBusLineItem,
  TBusStationItem,
  TSearchApiResponse,
  TSearchParams,
  TSearchPayload,
  TSearchProps,
  TSearchState,
} from 'ducks/search/types';
import {TSearchPoi} from 'types/App';

import {API_PATH, FULFILLED_STATE, PENDING_STATE, REJECTED_STATE} from 'constant/Api';

import fetcher from 'utils/fetcher';
import {getDefaultApiStatus} from 'utils/apis';
import {getPlaceTagList} from 'utils/general';
import {getListName, UserSearchLocationType} from 'utils/search';
import ua from 'utils/uaParser';
import {TVsmPublicTransportType} from 'ducks/userInteraction/types';

const ACTION_PREFIX = 'search';

const DEFAULT_PAGING_SIZE = 70;
const MAX_PAGE_SIZE = 5;

export const SEARCH_PAGING_SIZE = DEFAULT_PAGING_SIZE;
export const SEARCH_MAX_ITEM_SIZE = DEFAULT_PAGING_SIZE * MAX_PAGE_SIZE;

export const initialState: TSearchState = {
  ...getDefaultApiStatus<TSearchApiResponse>({
    collectionType: ESearchCollectionType.POI,
    ltrYn: null,
    poi: {
      docs: [],
      guideSearchDisplayText: '',
      userQuery: '',
      guideSearchKeyword: '',
      suggestReplaceKeyword: '',
      guideSearchType: EGuideSearchType.DEFAULT,
      suggestSearchType: ESuggestSearchType.DEFAULT,
    },
    busLine: {
      areaCityCodes: [],
      docs: [],
      totalCount: 0,
      userQuery: '',
    },
    busStation: {
      docs: [],
      totalCount: 0,
      userQuery: '',
    },
    totalCount: 0,
    list: [],
  }),
  sort: ESortOption.SCORE,
  locationType: UserSearchLocationType.get(),
  nowPage: 1,
  maxPage: 1,
  searchList: [],
  lastParam: undefined,
  guideSearchType: undefined,
  sortCityCode: undefined,
  sortCityCodeList: [],
  tmapFamousYn: false,
  openNowYn: false,
  poiParkYn: false,
  isWaitingReservation: false,
  categories: [],
};

const getApiInfoParams = (nowStoreState: StoreState) => ({
  ticketId: nowStoreState.userInfo.searchSessionKey || '',
  userKey: nowStoreState.userInfo.userKey || '',
  osType: ua.os.name || '',
  osVersion: ua.os.version || '',
  appVersion: ua.tmapAppVersion || '',
  resolution: `${document.documentElement.clientWidth || ''}*${
    document.documentElement.clientHeight || ''
  }`,
  fuel: nowStoreState.userInfo?.carInfo?.fuel || '',
});

export const fetchSearchList = createAsyncThunk<TSearchPayload, TSearchProps>(
  `${ACTION_PREFIX}/list`,
  async (props, creator) => {
    const storeState = creator.getState() as StoreState;

    const {lat: userRealLat, lon: userRealLon} = storeState.map.userPosition || {};
    const {lat: nowCenterLat, lon: nowCenterLon} = storeState.map.nowCenter || {};
    const {lastParam} = storeState.search;
    const {ltrYn} = storeState.search.data;
    const {fromRecommendedYn} = storeState.search;

    const params: TSearchParams = {
      lat: props.lat || nowCenterLat || 0,
      lon: props.lon || nowCenterLon || 0,
      userRealLat: props.userPosition?.lat || userRealLat || 0,
      userRealLon: props.userPosition?.lon || userRealLon || 0,
      query: props.query,
      searchLocationType: props.locationType || storeState.search.locationType,
      page: 1,
      size: DEFAULT_PAGING_SIZE,
      sort: props.sort || storeState.search.sort,
      guideSearchType: storeState.search.guideSearchType,
      collectionType: props.collectionType,
      cityCode: props.cityCode || undefined,
      categories: props.categories || storeState.search.categories,
      poiAdvertiseYn: props.poiAdvertiseYn || 'N',
      ...getApiInfoParams(storeState),
    };

    if (props?.usePrevCenter && lastParam) {
      params.lat = lastParam.lat;
      params.lon = lastParam.lon;
    }

    if (params.searchLocationType === ESearchLocationType.MAP) {
      params.userRealLat = params.lat;
      params.userRealLon = params.lon;
    }

    if (props.onMap) {
      params.searchLocationType =
        params.searchLocationType === ESearchLocationType.MAP
          ? ESearchLocationType.MAP_ON_MAP
          : ESearchLocationType.USER_ON_MAP;
      if (storeState.map.nowViewPort) {
        const {leftTop, rightTop, rightBottom, leftBottom} = storeState.map.nowViewPort;
        const geoPolygon = [leftTop, rightTop, rightBottom, leftBottom]
          .map((n) => `${n?.lat || 0},${n?.lon || 0}`)
          .join('_');

        params.geoPolygon = geoPolygon || '';
      }
    }

    const tmapFamousYn = props.tmapFamousYn || storeState.search.tmapFamousYn;
    const openNowYn = props.openNowYn || storeState.search.openNowYn;
    const poiParkYn = props.poiParkYn || storeState.search.poiParkYn;
    const isWaitingReservation =
      props.isWaitingReservation || storeState.search.isWaitingReservation;

    if (ltrYn || fromRecommendedYn) {
      params.fromRecommendedYn = fromRecommendedYn ? 'Y' : 'N';
      params.tmapFamousYn = tmapFamousYn ? 'Y' : 'N';
      params.openNowYn = openNowYn ? 'Y' : 'N';
      params.poiParkYn = poiParkYn ? 'Y' : 'N';
      // 마지막 api params로 보낼때만, featureOr를 설정 (그전엔 isWaitingReservation, 필터값으로 현재 같이 사용중 예약/웨이팅)
      params.featureOr = isWaitingReservation ? 'TMAP_WAITING,TMAP_RESERVATION' : '';
    }

    creator.dispatch(searchSlice.actions.setParam(params));

    const response = await fetcher.post(API_PATH.POST_SEARCH_LIST, params);
    return response.data;
  }
);

export const fetchSearchMore = createAsyncThunk<TSearchPayload>(
  `${ACTION_PREFIX}/listMore`,
  async (props, creator) => {
    const storeState = creator.getState() as StoreState;
    const {lastParam, nowPage, maxPage, data, sortCityCode} = storeState.search;

    if (!lastParam || nowPage >= maxPage) {
      return;
    }

    const param: TSearchParams = {
      ...lastParam,
      page: nowPage + 1,
      collectionType: data?.collectionType,
      cityCode: sortCityCode,
      ...getApiInfoParams(storeState),
    };

    creator.dispatch(searchSlice.actions.setParam(param));

    const response = await fetcher.post(API_PATH.POST_SEARCH_LIST, param);

    return response.data;
  }
);

const getPendingState = (state: TSearchState) => {
  state.loading = PENDING_STATE.loading;
  state.loaded = PENDING_STATE.loaded;
  state.error = undefined;
};

export const getFulfilledState = (state: TSearchState, action) => {
  state.nowPage = state.lastParam?.page || 1;
  state.loading = FULFILLED_STATE.loading;
  state.loaded = FULFILLED_STATE.loaded;
  state.error = FULFILLED_STATE.error;

  if (!action.payload) {
    return;
  }

  const collectionType = action.payload.data?.collectionType;
  state.data.collectionType = collectionType;
  state.data.ltrYn = action.payload.data.recommendedResultYn === 'Y' ? true : false;

  if (collectionType === ESearchCollectionType.POI) {
    const d = action.payload.data.poi;

    const list: TSearchPoi[] =
      d.docs?.map(
        (v: TSearchPoi): TSearchPoi => ({
          ...v,
          poiId: `${v.poiId}`,
          imageInfo: v.imageInfo || [],
          placeTags: getPlaceTagList(v.tag || v),
          distance: v.distance * 1000,
          userRealDistance: v.userRealDistance * 1000,
          listId: `${v.pkey}-${v.poiId}-${v.navSeq}`,
          listName: getListName(v),
          groupSubList: v.groupSubList.map((sub) => ({
            ...sub,
            placeTags: getPlaceTagList(sub.tag || sub),
            listId: `${v.pkey}-${v.poiId}-${v.navSeq}-${sub.seq}`,
            listName: getListName(sub),
          })),
          stationType: (v.stationType || '').toLocaleLowerCase() as TVsmPublicTransportType,
        })
      ) || [];

    state.data.poi = d;
    state.data.poi.guideSearchType = d.guideSearchType;
    state.data.poi.guideSearchDisplayText = d.guideSearchDisplayText;
    state.data.poi.userQuery = d.userQuery;
    state.data.poi.guideSearchKeyword = d.guideSearchKeyword;
    state.data.poi.suggestReplaceKeyword = d.suggestReplaceKeyword;
    state.data.poi.suggestSearchType = d.suggestSearchType;

    state.data.list = list;
    state.data.totalCount = d.totalCount;
    state.maxPage = Math.min(Math.floor(d.totalCount / DEFAULT_PAGING_SIZE) + 1, MAX_PAGE_SIZE);
    state.searchList = [...state.searchList, ...list] as TSearchPoi[];
  }

  if (collectionType === ESearchCollectionType.BUS_LINE) {
    const d = action.payload.data.busLine;
    const list: TBusLineItem[] = d.docs;

    state.data.busLine = d;
    state.data.list = list;
    state.data.totalCount = d.totalCount;
    state.maxPage = Math.min(Math.floor(d.totalCount / DEFAULT_PAGING_SIZE) + 1, MAX_PAGE_SIZE);
    state.searchList = [...state.searchList, ...list] as TBusLineItem[];

    if (!state.lastParam?.cityCode) {
      state.sortCityCodeList = d.areaCityCodes;
    }
  }

  if (collectionType === ESearchCollectionType.BUS_STATION) {
    const d = action.payload.data.busStation;
    const list: TBusStationItem[] = d.docs.map((v) => ({
      ...v,
      listId: `${v.pkey}-${v.busStationId}`,
      listName: getListName(v),
    }));

    state.data.busStation = d;
    state.data.list = list;
    state.data.totalCount = d.totalCount;
    state.maxPage = Math.min(Math.floor(d.totalCount / DEFAULT_PAGING_SIZE) + 1, MAX_PAGE_SIZE);
    state.searchList = [...state.searchList, ...list] as TBusStationItem[];
  }
};

const getRejectedState = (state: TSearchState, action) => {
  state.loading = REJECTED_STATE.loading;
  state.loaded = REJECTED_STATE.loaded;
  state.data = initialState.data;
  state.error = action.error;
};

const searchSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    resetList: (state, action: PayloadAction<{guideSearchType?: string} | undefined>) => {
      state.loaded = initialState.loaded;
      state.loading = initialState.loading;
      state.data = initialState.data;
      state.searchList = [];
      state.nowPage = 1;
      state.maxPage = 1;
      state.lastParam = undefined;
      state.guideSearchType = action.payload?.guideSearchType || undefined;
    },
    reset: (state) => {
      state.loaded = initialState.loaded;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.sort = initialState.sort;
      state.locationType = initialState.locationType;
      state.searchList = initialState.searchList;
      state.nowPage = initialState.nowPage;
      state.maxPage = initialState.maxPage;
      state.lastParam = initialState.lastParam;
      state.locationType = UserSearchLocationType.get();
      state.guideSearchType = undefined;
      state.sortCityCode = undefined;
      state.sortCityCodeList = [];
    },
    updateSort: (state, action: PayloadAction<ESortOption>) => {
      state.sort = action.payload;
    },
    resetSort: (state) => {
      state.sort = initialState.sort;
    },
    updateSortCityCode: (state, action: PayloadAction<ECityCode>) => {
      state.sortCityCode = action.payload || undefined;
    },
    updateLocationType: (state, action: PayloadAction<ESearchLocationType>) => {
      state.locationType = action.payload;
    },
    updateGuideSearchType: (state, action: PayloadAction<string>) => {
      state.guideSearchType = action.payload;
    },
    updatePrevGuideSearchType: (state, action: PayloadAction<string>) => {
      state.prevGuideSearchType = action.payload;
    },
    setParam: (state, action: PayloadAction<TSearchParams>) => {
      state.lastParam = action.payload;
    },
    setAddressSearch: (state, action: PayloadAction<Nullable<TAddressSearch>>) => {
      state.addressSearch = action.payload ? action.payload : undefined;
    },
    updateFilter: (state, action) => {
      state.data.ltrYn = action.payload.ltrYn ?? false;
      state.fromRecommendedYn = action.payload.fromRecommendedYn ?? false;
      state.tmapFamousYn = action.payload.tmapFamousYn ?? state.tmapFamousYn;
      state.openNowYn = action.payload.openNowYn ?? state.openNowYn;
      state.poiParkYn = action.payload.poiParkYn ?? state.poiParkYn;
      state.isWaitingReservation =
        action.payload.isWaitingReservation ?? state.isWaitingReservation;
    },
    updateCategories: (state, action) => {
      state.data.ltrYn = action.payload.ltrYn ?? false;
      state.fromRecommendedYn = action.payload.fromRecommendedYn ?? false;
      state.categories = action.payload.categories ?? state.categories;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchList.pending, getPendingState)
      .addCase(fetchSearchList.fulfilled, getFulfilledState)
      .addCase(fetchSearchList.rejected, getRejectedState)

      .addCase(fetchSearchMore.pending, getPendingState)
      .addCase(fetchSearchMore.fulfilled, getFulfilledState)
      .addCase(fetchSearchMore.rejected, getRejectedState);
  },
});

export default searchSlice;
